exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-antrenamente-bjj-brasov-js": () => import("./../../../src/pages/antrenamente-bjj-brasov.js" /* webpackChunkName: "component---src-pages-antrenamente-bjj-brasov-js" */),
  "component---src-pages-antrenamente-grappling-brasov-js": () => import("./../../../src/pages/antrenamente-grappling-brasov.js" /* webpackChunkName: "component---src-pages-antrenamente-grappling-brasov-js" */),
  "component---src-pages-antrenamente-kickboxing-brasov-js": () => import("./../../../src/pages/antrenamente-kickboxing-brasov.js" /* webpackChunkName: "component---src-pages-antrenamente-kickboxing-brasov-js" */),
  "component---src-pages-antrenamente-mma-brasov-js": () => import("./../../../src/pages/antrenamente-mma-brasov.js" /* webpackChunkName: "component---src-pages-antrenamente-mma-brasov-js" */),
  "component---src-pages-coaches-js": () => import("./../../../src/pages/coaches.js" /* webpackChunkName: "component---src-pages-coaches-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

